/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-mention-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mention-wrapper .ant-mention-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 32px;
  padding: 0;
  line-height: 1.5;
}
.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mention-wrapper .ant-mention-editor:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper .ant-mention-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mention-wrapper .ant-mention-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mention-wrapper .ant-mention-editor-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mention-wrapper .ant-mention-editor-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mention-wrapper .ant-mention-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper.disabled .ant-mention-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mention-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mention-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  display: block;
  color: #1890ff;
  text-align: center;
}
.ant-mention-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-mention-dropdown-item:hover {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item.focus,
.ant-mention-dropdown-item-active {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mention-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mention-dropdown-item-selected,
.ant-mention-dropdown-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  background-color: #f5f5f5;
}
.ant-mention-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
